<script setup>
import {ListWidget} from '@/paks/vu-widgets'
import {onMounted, reactive} from 'vue'

const props = defineProps({subject: String})

const Info = {
    Appweb: {
        learn: {
            title: 'Learn about Appweb',
            items: [
                {
                    date: '2022/10/26',
                    color: 'primary',
                    icon: '$tap',
                    title: 'Appweb Documentation',
                    subtitle: 'Full Appweb documentation.',
                    link: 'https://www.embedthis.com/appweb/doc/',
                },
            ],
        },
        news: {
            title: 'Appweb News',
            items: [
                {
                    date: '2024/07/18',
                    color: 'primary',
                    icon: '$tap',
                    title: 'Seamless Device Updates',
                    subtitle: 'Orchestrated OTA Device Updates',
                    link: 'https://www.embedthis.com/blog/builder/software-update.html',
                },
                {
                    date: '2022/11/1',
                    color: 'primary',
                    icon: '$tap',
                    title: 'Appweb Release 9',
                    subtitle: 'New Major Release',
                    link: 'https://www.embedthis.com/blog/2023/appweb-9.html',
                },
            ],
        },
    },
    Builder: {
        learn: {
            title: 'Learn about the Builder',
            items: [
            {
                    date: '2022/10/26',
                    color: 'accent',
                    icon: '$clipboard',
                    title: 'Getting started with the Builder',
                    subtitle: 'Overview of the Builder and how to create embedded products.',
                    link: 'https://www.embedthis.com/doc/builder/overview/',
                },
                {
                    date: '2022/10/26',
                    color: 'primary',
                    icon: '$tap',
                    title: 'Builder Documentation',
                    subtitle: 'Complete Builder documentation',
                    link: 'https://www.embedthis.com/doc/',
                },
            ],
        },
        news: {
            title: 'Builder News',
            items: [
                {
                    date: '2024/07/18',
                    color: 'accent',
                    icon: '$clipboard',
                    title: 'Builder Seamless Device Updates',
                    subtitle: 'Orchestrated OTA Device Updates',
                    link: 'https://www.embedthis.com/blog/builder/software-update.html',
                },
                {
                    date: '2022/10/26',
                    color: 'accent',
                    icon: '$tap',
                    title: 'Builder Hosted Device Clouds',
                    subtitle: 'Create device clouds without needing your own AWS account.',
                    link: 'https://www.embedthis.com/doc/clouds/creating/',
                },
            ],
        },
    },
    GoAhead: {
        learn: {
            title: 'Learn about GoAhead',
            items: [
                {
                    date: '2022/10/26',
                    color: 'accent',
                    icon: '$clipboard',
                    title: 'GoAhead Documentation',
                    subtitle: 'Full GoAhead Documentation',
                    link: 'https://www.embedthis.com/goahead/doc/',
                },
            ],
        },
        news: {
            title: 'GoAhead News',
            items: [
                {
                    date: '2024/07/18',
                    color: 'primary',
                    icon: '$tap',
                    title: 'Seamless Device Updates',
                    subtitle: 'Orchestrated OTA Device Updates',
                    link: 'https://www.embedthis.com/blog/builder/software-update.html',
                },
                {
                    date: '2022/10/26',
                    color: 'accent',
                    icon: '$clipboard',
                    title: 'GoAhead 6',
                    subtitle: 'Easy update to GoAhead 6',
                    link: 'https://www.embedthis.com/blog/2023/goahead-6.html',
                },
            ],
        },
    },
    Ioto: {
        learn: {
            title: 'Learn about Ioto',
            items: [
                {
                    date: '2022/10/26',
                    color: 'primary',
                    icon: '$tap',
                    title: 'Choosing your next embedded web server',
                    subtitle:
                        'Select Ioto as your next embedded web server. Blazing fast, rock solid.',
                    link: 'https://www.embedthis.com/blog/ioto/ioto-as-an-embedded-web-server.html',
                },
                {
                    date: '2022/10/26',
                    color: 'accent',
                    icon: '$clipboard',
                    title: 'Using the Ioto Agent',
                    subtitle: 'Extending Ioto with Custom Code.',
                    link: 'https://www.embedthis.com/blog/ioto/ioto-extending.html',
                },
            ],
        },
        news: {
            title: 'Ioto News',
            items: [
                {
                    date: '2024/07/18',
                    color: 'primary',
                    icon: '$tap',
                    title: 'Automated Actions',
                    subtitle: 'Make your device cloud intelligent, active and adaptive.',
                    link: 'https://www.embedthis.com/blog/ioto/cloud/start/ioto-automations.html',
                },
                {
                    date: '2022/10/26',
                    color: 'accent',
                    icon: '$clipboard',
                    title: 'Builder Hosted Device Clouds',
                    subtitle: 'Create device clouds without needing your own AWS account.',
                    link: 'https://www.embedthis.com/doc/clouds/creating/',
                },

            ],
        },
    },
    Manager: {
        learn: {
            title: 'Learn about the Device Manager',
            items: [
                {
                    date: '2022/10/26',
                    color: 'primary',
                    icon: '$tap',
                    title: 'Device Manager Overview',
                    subtitle: 'Customizable device manager for your devices',
                    link: 'https://www.embedthis.com/doc/manager/overview/',
                },
            ],
        },
        news: {
            title: 'Device Manager News',
            items: [
                {
                    date: '2022/10/26',
                    color: 'accent',
                    icon: '$clipboard',
                    title: 'Device Manager Display',
                    subtitle: 'Configure the Device Manager display',
                    link: 'https://www.embedthis.com/doc/manager/display/',
                },
            ],
        },
    },
}

const page = reactive({
    info: Info,
})

onMounted(async () => {})
</script>

<template>
    <v-container fluid class="news">
        <v-row v-if="page.info[subject]" _class="ga-3">
            <v-col cols="6">
                <ListWidget
                    :header="page.info[subject].news.title"
                    :value="page.info[subject].news.items" />
            </v-col>
            <v-col cols="6">
                <ListWidget
                    :header="page.info[subject].learn.title"
                    :value="page.info[subject].learn.items" />
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss">
.news {
    width: 100%;
    padding: 20px 0 16px 0 !important;
    .v-col {
        flex-grow: 1,
    }
}
a {
    text-decoration: none;
}
.list-widget {
    border-top: 2px rgb(var(--v-theme-accent)) solid !important;
    border-radius: 4px;
    margin-bottom: 16px;
}
</style>
