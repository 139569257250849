<script setup>
import {onBeforeMount, reactive, ref} from 'vue'
import {Feedback, Progress, State, clone, getModels, getRoutes} from '@/paks/vu-app'
import {stringToBase64} from '@/paks/js-base64'
import DashRange from './DashRange.vue'
import CssEdit from './CssEdit.vue'

const props = defineProps({
    board: Object,
    view: Object,
})
const emit = defineEmits(['input'])

const page = reactive({
    applying: false,
    askRange: false,
    builder: State.config.builder,
    board: {widgets: []},
    existing: false,
    grid: null,
    hasMetrics: null,
    layouts: true,
    multiple: null,
    saving: false,
    type: null,
})

const {Dashboard, Manager} = getModels()

//  Component refs
const self = ref(null)
const confirm = ref(null)

onBeforeMount(() => {
    if (props.board) {
        page.board = clone(props.board)
    }
    let board = page.board
    page.existing = board.name ? true : false
    page.type = board.type

    //  Set defaults
    board.range = board.range || {}
    board.css = board.css || []
    board.widgets = board.widgets || []
    board.widgetCss = board.widgetCss || []
    board.framed = board.framed == null ? true : board.framed
    board.full = board.full == null ? false : board.full
    board.layout = board.layout == null ? 'grid' : board.layout
    board.live = board.live == null ? true : board.live
    board.snap = board.snap == null ? true : board.snap
    board.toolbar = board.toolbar == null ? true : board.toolbar
    if (board.name == 'New Dashboard') {
        board.name = ''
    }
    page.hasMetrics =
        State.config.features.dash.metrics && State.cache.dashboard.widgets.filter((w) => w.metric).length
            ? true
            : false
    page.multiple = State.config.features?.dash?.multiple ? true : false
    page.grid = board.layout != 'grid' ? false : true
})

async function preSave() {
    let {board, type} = page
    if (type == 'dashboard') {
        if (board.name == 'New Dashboard') {
            throw new Error('Must set dashboard name')
        }
        if (board.id == null && (await Dashboard.get({name: board.name}))) {
            throw new Error('Dashboard name already in use')
        }
    }
    return true
}

async function apply() {
    let board = page.board
    let refresh = parseInt(board.refresh)
    if (refresh < 5) {
        refresh = 5
    }
    let params = {
        id: board.id,
        css: board.css,
        emulate: board.emulate,
        framed: board.framed,
        full: board.full,
        live: board.live,
        layout: page.grid ? 'grid' : 'exact',
        name: board.name,
        range: board.range,
        refresh: refresh,
        snap: page.grid ? true : false,
        toolbar: board.toolbar,
        widgets: board.widgets,
        widgetCss: board.widgetCss,
        type: board.type || 'dashboard',
    }
    if (board.type == 'dashboard') {
        if (board.id) {
            board = await Dashboard.update(params)
        } else {
            board = await Dashboard.create(params)
        }
        await Dashboard.set(board)
    } else if (props.view) {
        let display = State.app.display
        let {views, key} = getRoutes().getDisplayView(props.view.path)
        if (key >= 0) {
            let view = Object.assign({}, props.view)
            delete board.remaining
            view.board = board
            views[key] = view
            await getRoutes().replaceRoutes(display)
        }
        let manager = await Manager.get()
        let data = stringToBase64(JSON.stringify(display, null, 4))
        await Manager.provision({id: manager.id, assets: {display: {data, size: data.length, name: 'display.json5'}}})
    }
}

async function save() {
    await apply()
    emit('input')
}

async function copy() {
    if (
        !(await confirm.value.ask(`Do you want to create a copy of the dashboard called "${page.board.name} 2"?`, {
            width: 600,
        }))
    ) {
        return
    }
    let board = (page.board = clone(page.board))
    delete board.id
    board.name = `${board.name} 2`
    await save()
}

async function deleteDash() {
    let board = page.board
    if (!board.name) {
        return
    }
    if (!(await confirm.value.ask(`Do you want to delete the dashboard"?`))) {
        return
    }
    Progress.start('medium')
    await Dashboard.remove({id: board.id})
    await Dashboard.set()
    Progress.stop()

    Feedback.info('Dashboard Deleted')
    emit('input')
}

async function changeName() {
    let name = page.board.name
    if (name == 'New Dashboard') {
        //  Initialize and clear the name
        page.board = {layout: 'grid', snap: true, widgets: [], toolbar: true}
    } else {
        //  See if the name exists
        let board = await Dashboard.get({name})
        if (page.board.id) {
            //  Changing from a current dashboard to a new one
            if (board) {
                //  Found
                page.board = board
            }
        } else {
            //  Setting a name for a new dashboard. Check if name already in use
            if (board) {
                let validate = self.value.validate
                if (validate) {
                    validate.clear()
                    await validate.fieldError(self.value, 'name', 'Dashboard name already in use')
                }
            } else {
                page.board.name = name
            }
        }
    }
}
</script>

<template>
    <vu-form
        class="board-edit"
        help="/doc/ui/dashboard/dashboard-edit.html"
        ref="self"
        :data="page"
        :pre-save="preSave"
        :save="save"
        :title="`${page.existing ? 'Modify' : 'Add'} Presentation`">
        <vu-input
            v-if="page.multiple"
            v-model="page.board.name"
            label="Dashboard Name"
            name="name"
            placeholder="Enter name"
            type="text"
            class="mb-2"
            @change="changeName" />

        <div class="vrow">
            <vu-input
                label="Layout"
                v-model="page.grid"
                cols="4"
                type="switch"
                inset
                :suffix="page.grid ? 'Grid' : 'Exact'" />

            <vu-input type="switch" class="live-data" v-model="page.board.live" label="Live Data" cols="4" inset />
            <div class="vcol-4" />
        </div>
        <div class="vrow">
            <vu-input label="Framed Widgets" v-model="page.board.framed" type="switch" cols="4" inset />
            <vu-input type="switch" v-model="page.board.full" label="Full Screen" cols="4" inset />
            <vu-input type="switch" v-model="page.board.toolbar" label="Show Toolbar" cols="4" inset />
        </div>

        <vu-input
            type="text"
            v-model="page.board.refresh"
            label="Refresh"
            placeholder="Seconds"
            :clearable="false"
            suffix="secs"
            cols="6" />

        <vu-input
            v-if="!State.app.mobile"
            type="select"
            v-model="page.board.emulate"
            label="Emulate"
            :items="['None', 'Mobile', 'Desktop']"
            cols="6" />

        <vu-input v-if="page.hasMetrics" v-model="page.askRange" type="checkbox" label="Time Range" hide-details />

        <DashRange v-if="page.hasMetrics && page.askRange" v-model="page.board.range" mode="date" />

        <CssEdit v-model="page.board.css" title="CSS Properties" class="mb-2" />
        <CssEdit v-model="page.board.widgetCss" title="Widget CSS Properties" class="mb-3" />

        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="accent" @click="apply" :loading="page.applying">Apply</v-btn>
            <v-btn v-if="page.type == 'dashboard'" size="small" color="teal" @click="copy">Copy</v-btn>
            <v-btn size="small" color="none" @click="emit('input', null)">Cancel</v-btn>
            <v-btn size="small" color="error" v-if="page.multiple && page.board.id" @click="deleteDash">Delete</v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.board-edit {
    .pick-board {
        margin: 16px 0 0px 0;
        width: 100%;
        .v-input__control {
            border-color: black;
            border-radius: 4px;
        }
    }
    .v-select-list {
        padding: 0;
        border: solid 1px rgb(var(--v-theme-border-lighten-1));
        border-radius: 0;
    }
    h3 {
        font-weight: normal;
        color: rgb(var(--v-theme-text));
    }
    .layouts {
        margin: 10px 0 20px 0;
    }
    .actions {
        margin: 20px 0 20px 0;
    }
}
</style>
