/*
    BuilderSchema.js - Builder schema
 */

import {MetricSchema} from './MetricSchema.js'

const Match = {
    cloud: /^[^~`!@#$%^&\*(){}\[\]|\\\/:;,?=+]+$/,
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    name: /^[^<>~`!@#$%^&\*(){}\[\]|\\\/:;?=+]+$/,
    role: /^user|admin|owner|support|super$/,
    simpleName: /^[a-zA-Z0-9 ]+$/,
    accountName: /^[a-zA-Z0-9 @._-]+$/,
    ulid: /^[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}$/,
}

const Range = {
    anchor: {type: 'string', enum: ['absolute', 'relative'], default: 'relative'},
    start: {type: 'date'},
    period: {type: 'number', default: 28 * 86400},
    refresh: {type: 'number', default: 60},
    override: {type: 'boolean', default: false},
    timezone: {type: 'string', enum: ['local', 'utc'], default: 'local'},
}

const Schema = {
    format: 'onetable:1.1.0',
    version: 'latest',
    params: {
        isoDates: true,
        nulls: false,
        timestamps: false,
        typeField: '_type',
    },
    indexes: {
        primary: {
            hash: 'pk',
            sort: 'sk',
            description: 'Primary index',
        },
        gs1: {
            hash: 'gs1pk',
            sort: 'gs1sk',
            description: 'General secondary index',
            project: 'all',
        },
    },
    models: {
        Account: {
            pk: {type: 'string', value: 'account#${id}'},
            sk: {type: 'string', value: 'account#'},

            gs1pk: {type: 'string', value: 'account#'},
            gs1sk: {type: 'string', value: 'account#${email}#${id}'},

            activity: {type: 'date'}, //  Most recent account activity

            billing: {
                type: 'object',
                default: {},
                schema: {
                    address: {type: 'string'},
                    agreement: {type: 'boolean', default: false},
                    cardId: {type: 'string'},
                    canWire: {type: 'boolean', default: false},
                    company: {type: 'string'}, //  Company Name
                    country: {type: 'string'}, //  Billing country for tax
                    credit: {type: 'number', default: 0}, //  Credit to apply to next invoice
                    error: {type: 'string'}, //  Card / billing error
                    invoiceEmail: {type: 'string'}, //  Email address for invoices
                    memo: {type: 'string'}, //  General memo for invoice
                    order: {type: 'string'}, //  Purchase order
                    renew: {type: 'date'}, //  Next agreement renewal date
                    renewing: {type: 'boolean', default: true},
                    stripeId: {type: 'string', crypt: true, size: 64},
                    subject: {type: 'string'},
                    terms: {type: 'number', default: 0}, //  Payment terms if wire enabled
                    wire: {type: 'boolean', default: false}, //  Use payment by wire transfer
                    showTax: {type: 'boolean', default: true}, //  Show withholding tax as a separate item
                    tax: {type: 'number', default: 0}, //  Withholding tax rate
                },
                role: 'admin',
            },

            closed: {type: 'boolean', default: false}, //  Account has been closed
            created: {type: 'date'},
            email: {type: 'string', required: true, unique: true}, //  Billing email
            id: {type: 'string', generate: 'ulid'},
            lock: {type: 'date', default: 0, discard: true},

            metrics: {
                type: 'object',
                default: {},
                schema: {
                    //  Account billing and usage metrics
                    agents: {type: 'array', partial: false, default: []},
                    balance: {type: 'number', default: 0},
                    connected: {type: 'number', default: 0}, //  Connected to the cloud this month
                    clouds: {type: 'number', default: 0},
                    customer: {type: 'boolean', default: false},
                    dashboards: {type: 'number', default: 0},
                    devices: {type: 'number', default: 0}, //  Devices made this month
                    developer: {type: 'number', default: 0},
                    emails: {type: 'object', default: {}},
                    interest: {type: 'array', partial: false, default: []},
                    notifications: {type: 'number', default: 0},
                    plans: {type: 'number', default: 0},
                    products: {type: 'number', default: 0},
                    software: {type: 'number', default: 0},
                    support: {type: 'number', default: 0},
                    users: {type: 'number', default: 0},
                },
                role: 'support',
            },

            name: {type: 'string', required: true, unique: true},
            suspended: {type: 'boolean'},
        },

        Alert: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'alert#${id}'},

            gs1pk: {type: 'string', value: 'alert#'},
            gs1sk: {type: 'string', value: 'alert#${cloudId}#${name}#${seq}#${id}'},

            seq: {type: 'string'},
            accountId: {type: 'string', required: true},
            cloudId: {type: 'string', notes: 'Owning device cloud ID'},
            context: {type: 'object'},
            count: {type: 'number'}, //  Count of actions
            id: {type: 'string', generate: 'ulid'},
            name: {type: 'string', required: true}, //  Trigger.name
            message: {type: 'string'}, //  Text message regarding alert
            severity: {type: 'string'},
            subject: {type: 'string'},
            resolved: {type: 'boolean'},
            timestamp: {type: 'date'},
        },

        Billing: {
            //  PK does not include accountId so the billing records are outside the account scope
            pk: {type: 'string', value: 'billing#'},
            sk: {type: 'string', value: 'billing#${accountId}#${id}'},
            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            id: {type: 'string', generate: 'ulid'},
            item: {type: 'string'},
            details: {type: 'object'},
            amount: {type: 'number', default: 0},
        },

        Card: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'card#${id}'},
            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            current: {type: 'boolean', default: false},
            error: {type: 'string'},
            failures: {type: 'number', default: 0}, //  'number' of times the charge has been retried
            id: {type: 'string', generate: 'ulid'},
            name: {type: 'string', required: true},
            number: {type: 'string', required: true}, //  Store only the last 4 digits
            month: {type: 'string', required: true},
            stripeToken: {type: 'string', crypt: true, size: 64, discard: true},
            submitted: {type: 'date'}, //  When last submitted to stripe for payment
            year: {type: 'string', required: true},
        },

        Cloud: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'cloud#${id}'},
            gs1pk: {type: 'string', value: 'cloud#'},
            gs1sk: {type: 'string', value: 'cloud#${id}'},

            accountId: {type: 'string'},
            api: {type: 'string'}, // Hub (Device cloud) API endpoint
            apiGateway: {type: 'string'}, // Manager API Gateway ID
            awsAccount: {type: 'string', size: Match.awsIdSize},
            connected: {type: 'boolean', default: false}, // Cloud connected and has passed tests
            deviceRole: {type: 'string', default: 'IotoDeviceAccess'}, // IAM role for device keys
            devices: {type: 'number', default: 0},
            enable: {type: 'boolean', default: true},
            error: {type: 'string'},
            external: {type: 'string', discard: true}, // Do not disclose to browser
            host: {type: 'string'},
            hubs: {type: 'number', default: 0},
            id: {type: 'string', generate: 'uid(12)'},
            iotPolicy: {type: 'string', default: 'IotoThingPolicy'},
            load: {type: 'number', default: 0},
            name: {type: 'string', required: true, validate: Match.cloud},
            open: {type: 'boolean', default: true},
            planId: {type: 'string'},
            provisioned: {type: 'date'},
            quota: {type: 'object'},
            region: {type: 'string', required: true},
            roleArn: {type: 'string', discard: true},
            schema: {type: 'object', default: {}},
            shadows: {type: 'boolean', default: false},
            stack: {type: 'string'},
            status: {type: 'string'},
            suspended: {type: 'boolean'},
            sync: {type: 'boolean', default: true},
            tenants: {type: 'number', default: 0},
            trailBucket: {type: 'string'},
            type: {type: 'string', default: 'hosted'},
            version: {type: 'number'},
            userPoolId: {type: 'string'}, // Cognito user pool ID
            userPoolClient: {type: 'string'}, // Cognito user pool Client ID
        },

        Counters: {
            pk: {type: 'string', value: 'counters#'},
            sk: {type: 'string', value: 'counters#'},
            quote: {type: 'number', default: 4210},
            invoice: {type: 'number', default: 2470},
        },

        Dashboard: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'dashboard#${id}'},

            gs1pk: {type: 'string', value: 'dashboard#'},
            gs1sk: {type: 'string', value: 'dashboard#${id}'},

            accountId: {type: 'string'},
            css: {type: 'array', default: [], partial: false},
            design: {type: 'boolean', default: true},
            emulate: {type: 'string'},
// MOB REMOVE
fixed: {type: 'boolean', default: false},
            framed: {type: 'boolean', default: true},
            full: {type: 'boolean', default: false},
            id: {type: 'string', generate: 'ulid'},
            layout: {type: 'string', default: 'grid'},
            live: {type: 'boolean', default: true},
            name: {type: 'string', required: true, validate: Match.name},
            range: {type: 'object', default: {}, partial: false, schema: Range},
            refresh: {type: 'number'},
            //  MOB - is this required?
            snap: {type: 'boolean', default: true},
            toolbar: {type: 'boolean', default: true},
            type: {type: 'string'},
            widgetCss: {type: 'array', default: [], partial: false},

            widgets: {
                type: 'array',
                partial: false,
                default: [],
                items: {
                    type: Object,
                    default: {},
                    partial: false,
                    schema: {
                        action: {
                            type: 'object',
                            default: {},
                            schema: {
                                type: {type: 'string'},
                                target: {type: 'string'},
                                conditions: {type: 'array', notes: 'Automation Trigger Conditions'},
                            },
                        },
                        accept: {type: 'string'}, // File input accept type
                        anchor: {type: 'object', partial: true, default: {}}, //  Anchor side
                        axes: {type: 'object'}, // Graph axes
                        cloudId: {type: 'string'}, //  Cloud ID
                        css: {type: 'array', default: [], partial: false},

                        //  DEPRECATE
                        dashboard: {type: 'string'}, //  Dashboard widget to select another dashboard
                        datetime: {type: 'string'}, // Date input datetime
                        dimensions: {type: 'object', default: {}}, //  Metric and data dimensions
                        field: {type: 'string'}, //  Database field or input form field
                        fields: {type: 'array'}, //  Database fields for table widget
                        fixed: {type: 'boolean'}, //  Lock down changes
                        form: {type: 'string'}, // Input form name
                        footer: {type: 'string'}, // Widget footer
                        format: {type: 'string'}, // Text and numeric format
                        framed: {type: 'boolean'}, // Widget has a frame
                        header: {type: 'string'}, // Widget header text
                        height: {type: 'string'}, //  Pixel height
                        id: {type: 'string'}, //  Unique widget UUID within the dashboard only
                        input: {type: 'string'}, // Input type
                        items: {type: 'string'}, //  Input items (JSON)
                        label: {type: 'string'}, // Input label
                        legend: {type: 'object'}, // TBD
                        left: {type: 'string'}, //  Fraction of viewport (0-1) or 'rest'
                        limit: {type: 'number'}, //  Item limit
                        max: {type: 'number'}, // Maximum data value
                        min: {type: 'number'}, // Minimum data value
                        metric: {type: 'string'}, //  CloudWatch Metric
                        model: {type: 'string'}, //  Database model
                        multiple: {type: 'boolean'}, //  Multiple selection
                        namespace: {type: 'string'}, //  Metric namespace
                        options: {type: 'object'}, // Custom options
                        pivot: {type: 'string'}, // Table pivot column
                        placeholder: {type: 'string'}, // Input placeholder
                        prefix: {type: 'string'}, // Value prefix
                        presentation: {type: 'string'}, //  Presentation style
                        range: {type: 'object', default: {}, partial: false, schema: Range},
                        region: {type: 'string'}, //  Region for metrics
                        rows: {type: 'number'}, //  Text area rows
                        show: {type: 'string'}, //  Show expression
                        statistic: {type: 'string'}, //  Math stat
                        subtitle: {type: 'string'}, //  Table subtitle
                        suffix: {type: 'string'}, // Value suffix
                        text: {type: 'string'}, // Static widget value
                        ticks: {type: 'number'}, // Number of ticks
                        timezone: {type: 'string'}, // Date input timezeon
                        title: {type: 'string'}, //  Widget title
                        top: {type: 'string'}, //  Top position
                        type: {type: 'string'}, //  Widget type
                        url: {type: 'string'}, //  Resource URL
                        validate: {type: 'string'}, //  RegExp validation
                        width: {type: 'string'}, //  Fraction of viewport (0-1)
                        z: {type: 'number'}, //  Z order
                    },
                },
            },
        },

        Device: {
            pk: {type: 'string', value: 'd#${accountId}'},
            sk: {type: 'string', value: 'd#${id}'},
            id: {type: 'string', required: true},
            accountId: {type: 'string', map: 'ac', reference: 'Account:primary:id=accountId'},
            managerAccountId: {type: 'string', map: 'ma'},
            cloudId: {type: 'string', map: 'cl', reference: 'Cloud:primary:id=cloudId'},
            productId: {type: 'string', map: 'pr', reference: 'Product:primary:id=productId'},
            test: {type: 'boolean', map: 'te'},
        },

        Invoice: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'invoice#${id}'},

            gs1pk: {type: 'string', value: 'invoice#'},
            gs1sk: {type: 'string', value: 'invoice#${accountId}#${id}'},

            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            amount: {type: 'number', default: 0}, //  Invoice amount excluding tax
            date: {type: 'date'},
            description: {type: 'string', required: true}, //  Invoice description
            due: {type: 'date', required: true}, //  When invoice is due
            items: {type: 'array', partial: false, required: true}, //  Invoice items
            id: {type: 'string', generate: 'ulid'},
            memo: {type: 'string'}, //  Memo for invoice
            nextReminder: {type: 'date'}, //  Next reminder email
            number: {type: 'string', required: true},
            order: {type: 'string'}, //  Purchase order number
            paid: {type: 'number', default: 0}, //  Amount paid off this invoice
            pdf: {type: 'string'}, //  S3 filename for invoice PDF
            receipt: {type: 'string', size: 64}, //  Stripe receipt ID
            submitted: {type: 'date'}, //  When submitted to stripe for payment
            tax: {type: 'number', default: 0, required: true}, //  Invoice tax
            terms: {type: 'number', default: 0},
            total: {type: 'number', default: 0, required: true}, //  Invoice total
        },

        Issue: {
            pk: {type: 'string', value: 'issue#${id}'},
            sk: {type: 'string', value: 'issue#'},

            gs1pk: {type: 'string', value: 'issue#'},
            gs1sk: {type: 'string', value: 'issue#${subject}#${id}'},

            attachments: {type: 'array', partial: false, default: []},
            cve: {type: 'string'},
            date: {type: 'date'},
            description: {type: 'string', required: true},
            id: {type: 'string', generate: 'ulid'},
            impacted: {type: 'string'},
            notify: {type: 'boolean', default: true},
            priority: {
                type: 'string',
                default: 'Low',
                enum: ['None', 'Low', 'Medium', 'High', 'Critical'],
            },
            public: {type: 'boolean', default: true},
            recommend: {type: 'string'},
            release: {type: 'string'},
            status: {type: 'string', required: true, enum: ['Open', 'Closed', 'Fixed']},
            subject: {type: 'string', required: true},
            tags: {type: 'array', partial: false, default: []},
            title: {type: 'string', required: true},
            type: {
                type: 'string',
                required: true,
                enum: ['Bug', 'Feature', 'Discussion', 'Release', 'Security'],
            },
        },

        Manager: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'manager#${id}'},

            gs1pk: {type: 'string', value: 'manager#'},
            gs1sk: {type: 'string', value: 'manager#${id}'},

            accountId: {type: 'string'},
            app: {type: 'string', hint: 'Zip file name'},
            certArn: {type: 'string'},
            cloudFront: {type: 'string'},
            cloudId: {type: 'string', required: true},
            css: {type: 'string'},
            distributionId: {type: 'string'},
            display: {type: 'string'},
            domain: {type: 'string'},
            error: {type: 'string'},
            id: {type: 'string', generate: 'ulid'},
            logo: {type: 'string'},
            name: {type: 'string', required: true, validate: Match.cloud},
            owner: {type: 'string'},
            priorDomain: {type: 'string'},
            provisioned: {type: 'date'},
            region: {type: 'string', required: true},
            title: {type: 'string'},
            type: {type: 'string', hint: 'Copy of cloud.type'},
            version: {type: 'string'},
        },

        Notice: {
            pk: {type: 'string', value: 'notice#'},
            sk: {type: 'string', value: 'notice#${id}'},
            id: {type: 'string', generate: 'ulid'},
            info: {type: 'object'},
            maintenance: {type: 'object'},
            start: {type: 'date'},
            end: {type: 'date'},
        },

        Notification: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'notification#${id}'},

            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            channel: {type: 'string', enum: ['email', 'eventbridge', 'http', 'lambda', 'sms']},
            data: {type: 'string'},
            destination: {type: 'string'},
            enable: {type: 'boolean', default: true},
            format: {type: 'string', enum: ['html', 'json', 'text']},
            headers: {type: 'object'},
            id: {type: 'string', generate: 'ulid'},
            method: {type: 'string'},
            name: {type: 'string', required: true},
            region: {type: 'string'},
        },

        Plan: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'plan#${id}'},

            gs1pk: {type: 'string', value: 'plan#'},
            gs1sk: {type: 'string', value: 'plan#${id}'},

            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            id: {type: 'string', generate: 'ulid'},

            adjustments: {type: 'array', partial: false, default: [], role: 'support'}, //  Billing adjustments
            advance: {type: 'boolean', default: true},
            agent: {type: 'string'},
            align: {type: 'boolean', default: true},
            count: {type: 'number', default: 0},

            current: {
                type: 'object',
                default: {units: 0},
                schema: {
                    //  Current (paid) subscription details
                    count: {type: 'number', default: 0},
                    end: {type: 'date'},
                    license: {type: 'string'},
                    period: {type: 'string'},
                    price: {type: 'number', default: 0},
                    scope: {type: 'string'},
                    start: {type: 'date'},
                    units: {type: 'number', default: 0},
                    upfront: {type: 'number', default: 0},
                },
            },

            discount: {type: 'number', default: 0}, //  Optional plan discount
            end: {type: 'date'}, //  End of plan period
            fixed: {type: 'boolean', default: false}, //  Lock down pricing
            interest: {type: 'array', partial: false, default: []},
            lastBilled: {type: 'date'}, //  When last billed
            license: {type: 'string'}, //  License: eval, opensource, commercial, agreement
            memo: {type: 'string'}, //  Memo for invoice
            nextReminder: {type: 'date'}, //  Next reminder email
            notify: {type: 'array', default: []}, //  Email addresses for product alerts

            override: {type: 'object'},
            pending: {type: 'boolean', default: true}, //  Purchase must be confirmed by customer
            period: {type: 'string'}, //  Billing period
            prepay: {type: 'boolean', default: false}, //  Prepay before due date
            //  Developer support hour purchases
            purchases: {
                type: 'array',
                partial: false,
                default: [],
                items: {
                    type: 'object',
                    partial: false,
                    schema: {
                        date: {type: 'date'},
                        units: {type: 'number', default: 0},
                        expired: {type: 'number', default: 0},
                    },
                },
            },
            start: {type: 'date'}, //  Start of next plan period
            scope: {type: 'string'}, //  Agent scope
            sliding: {type: 'boolean', default: false}, //  Sliding scale
            test: {type: 'number', default: 0}, //  Metered number of test units
            type: {type: 'string', required: true},
            units: {type: 'number', default: 0},
        },

        //  Ticket post
        Post: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'post#${ticketId}#${id}'},

            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            attachments: {type: 'array', partial: false, default: []},
            author: {type: 'string'},
            created: {type: 'date'},
            message: {type: 'string'},
            id: {type: 'string', generate: 'ulid'},
            ticketId: {type: 'string', required: true},
        },

        Product: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'product#${id}'},

            gs1pk: {type: 'string', value: 'product#'},
            gs1sk: {type: 'string', value: 'product#${id}'},

            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            description: {type: 'string'},
            id: {type: 'string', generate: 'ulid'},
            name: {type: 'string', required: true, unique: true, scope: '${accountId}'},
            planId: {type: 'string'},
            suspended: {type: 'boolean'},
            url: {type: 'string'},
        },

        Software: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'software#${id}'},
            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            created: {type: 'date'},
            checksum: {type: 'string'},
            //  Must not be required as removing clouds will remove the cloudId
            cloudId: {type: 'string'},
            description: {type: 'string'},
            id: {type: 'string', generate: 'ulid'},
            enable: {type: 'boolean', default: true},
            limit: {type: 'number', default: 0, notes: 'Count of devices to update'},
            image: {type: 'string'},
            percentage: {type: 'number', default: 100, notes: 'Perentage of devices to update'},
            period: {type: 'number', default: 3600, notes: 'Rate period in seconds'},
            productId: {type: 'string', required: true},
            policy: {type: 'string'},
            rate: {type: 'number', default: 0, notes: 'Number of updates per period'},
            size: {type: 'number'},
            // url:            { type: 'string' },
            version: {
                type: 'string',
                required: true,
                scope: '${accountId}#${productId}#${version}',
            },
        },

        Test: {
            pk: {type: 'string', value: 'test#'},
            sk: {type: 'string', value: 'test#${id}'},
            id: {type: 'string', generate: 'ulid'},
        },

        Ticket: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'ticket#${id}'},

            //  Support only
            gs1pk: {type: 'string', value: 'ticket#'},
            gs1sk: {type: 'string', value: 'ticket#${accountId}'},

            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            assigned: {type: 'string'},
            cc: {type: 'string'},
            created: {type: 'date'},
            hours: {type: 'number', default: 0},
            id: {type: 'string', generate: 'ulid'},
            productId: {type: 'string'},
            subject: {type: 'string', required: true},
            severity: {type: 'string', required: true, enum: ['low', 'medium', 'high', 'critical']},
            status: {
                type: 'string',
                required: true,
                enum: ['open', 'closed', 'progressing', 'pending-customer-action'],
            },
            updated: {type: 'date', required: true},
            work: {
                type: 'array',
                partial: false,
                default: [],
                items: {
                    type: Object,
                    default: {},
                    partial: false,
                    schema: {
                        date: {type: 'date'},
                        description: {type: 'string'},
                        hours: {type: 'number'},
                    },
                },
            },
        },

        Token: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'token#${id}'},

            gs1pk: {type: 'string', value: 'token#'},
            gs1sk: {type: 'string', value: 'token#${id}'},

            //  Cannot set required for accountId as HubSchema has a manager accountId but is not required
            //  And gateway signature validation needs to support both
            accountId: {type: 'string', reference: 'Account:primary:id=accountId'},
            cloudId: {type: 'string', reference: 'Cloud:primary:id=cloudId', role: 'support'},
            description: {type: 'string', required: true, validate: Match.name},
            enable: {type: 'boolean', default: true},
            expires: {type: 'date', ttl: true},
            id: {type: 'string', generate: 'ulid'},
            owner: {type: 'string', role: 'support'},
            role: {type: 'string', required: true},
            type: {type: 'string', required: true, validate: Match.name},
        },

        Usage: {
            pk: {type: 'string', value: 'usage#1'},
            sk: {type: 'string', value: 'usage#'},

            cancellations: {type: 'number', default: 0}, //  Change in customer count this billing cycle
            customers: {type: 'number', default: 0}, //  Total paying customers
            expired: {type: 'number', default: 0}, //  'number' of expired trials
            eval: {type: 'number', default: 0}, //  'number' of active eval accounts
            trials: {type: 'number', default: 0}, //  'number' of active trials
            sessions: {type: 'number', default: 0}, //  'number' of login sessions

            ytd: {type: 'number', default: 0}, //  Revenue year to date
            tax: {type: 'number', default: 0}, //  Tax year to date
            mtd: {type: 'number', default: 0}, //  Monthly fevenue to date
            mrr: {type: 'number', default: 0}, //  Monthly recurring revenue
            yrr: {type: 'number', default: 0}, //  Yearly recurring revenue
            forecast: {type: 'number', default: 0}, //  Forecast fiscal year revenue
            lastMonth: {type: 'number', default: 0}, //  Revenue last month (prior mtd)
            lastYear: {type: 'number', default: 0}, //  Revenue last year (prior ytd)

            dayAccounts: {type: 'number', default: 0}, //  Active accounts last 24 hours
            weekAccounts: {type: 'number', default: 0},
            monthAccounts: {type: 'number', default: 0},
            accounts: {type: 'number', default: 0}, //  Total accounts

            lastBilling: {type: 'date'}, //  When billing last run
        },

        User: {
            pk: {type: 'string', value: 'account#${accountId}'},
            sk: {type: 'string', value: 'user#${id}'},
            gs1pk: {type: 'string', value: 'user#'},
            gs1sk: {type: 'string', value: 'user#${email}'},

            accountId: {type: 'string', required: true, reference: 'Account:primary:id=accountId'},
            activity: {type: 'date'},
            email: {type: 'string', required: true, validate: Match.email, unique: true},
            first: {type: 'string'},
            id: {type: 'string', generate: 'ulid'},
            invite: {type: 'string'},
            last: {type: 'string'},
            preferences: {type: 'object', default: {signposts: true}},
            role: {type: 'string', required: true, validate: Match.role},
            state: {type: 'string', enum: ['accepted', 'invited']},
        },
    },
}

Schema.models.Metric = MetricSchema.models.Metric

Schema.Match = Match

export default Schema
