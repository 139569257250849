<script setup>
import {onMounted, reactive} from 'vue'
import {Feedback, State, fetch, waitRender} from '@/paks/vu-app'
import {User} from '@/models'

defineExpose({close})

const page = reactive({
    comments: null,
    href: null,
    html: null,
    results: null,
    suggest: false,
    search: null,
    toc: null,
})

onMounted(async () => {
    page.html = State.app.help ? ' ' : null
    if (State.app.help) {
        await loadPage({href: State.app.help})
    }
})

async function loadPage(item) {
    try {
        let href = item.href
        if (href.indexOf('/doc/') != 0) {
            if (href[0] == '/') {
                href = href.slice(1)
            }
            href = `/doc/${href}`
        }
        page.href = href
        let url = `${window.location.origin}/${href.slice(1)}`
        let html = await fetch(url, {method: 'GET', noparse: true, nologout: true})
        if (html.indexOf("doesn't work properly without JavaScript") > 0) {
            throw new Error('Missing page')
        }
        page.html = html
        await catchLinks()
    } catch (err) {
        Feedback.error(`Cannot find page ${item.href}`)
    }
}

async function catchLinks() {
    await waitRender()
    let panel = document.getElementById('help-card').parentElement
    if (panel) {
        panel.scrollTop = 0
    }
    let el = document.getElementById('docpage')
    for (let link of el.querySelectorAll('a')) {
        link.addEventListener(
            'click',
            function (e) {
                let target = e.currentTarget
                let parent = self.value.$parent
                if (target.host == window.location.host) {
                    let path = target.pathname
                    if (path.indexOf('/doc/kb/') == 0) {
                        parent.loadPage({href: path})
                    } else if (path.indexOf('/doc/ui/') == 0) {
                        parent.loadPage({href: path})
                    } else {
                        parent.navigate(path)
                    }
                    e.preventDefault()
                }
            },
            false
        )
    }
}

function close() {
    if (page.toc) {
        page.page = null
    }
    page.results = null
    page.search = null
}

async function sendSuggestion() {
    await User.comment({
        subject: 'User Suggestion to Improve Documentation',
        url: page.href || window.location.pathname,
        comments: page.comments,
        email: State.auth.user.email,
        account: State.auth.account.id,
    })
    page.comments = 'Thank you!'
    Feedback.info('Thankyou for Your Suggestion')
}

function launch() {
    window.open('https://www.embedthis.com/doc/builder/', '_blank')
}
</script>

<template>
    <v-container fluid class="help-doc" ref="self">
        <v-row class="doc-page" :class="State.app.dark ? 'dark' : 'light'" v-if="page.html">
            <v-col class="px-2">
                <div v-html="page.html" id="docpage" />
                <v-btn class="mt-2" size="small" color="accent" v-if="!page.suggest" @click="page.suggest = true">
                    Suggestion to Improve this Page
                </v-btn>
                <div v-if="page.suggest" class="my-4 suggest">
                    <h4>Please tell us your thoughts about how to improve this page.</h4>
                    <v-textarea
                        v-if="page.suggest"
                        class="mt-3"
                        v-model="page.comments"
                        placeholder="Comments..."></v-textarea>
                    <v-btn size="small" color="accent" @click="sendSuggestion">Send</v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss">
.help-doc {
    height: 100%;

    .help-index {
        padding: 0px 20px 0 20px;
        .v-expansion-panel {
            margin-top: 0;
            .v-expansion-panel-header {
                font-size: 1rem;
                color: rgb(var(--v-theme-text));
                padding: 16px;
                min-height: 46px;
                height: 46px;
            }
            .v-expansion-panel-header--active {
                background: rgb(var(--v-theme-background-lighten-1));
            }
            .v-expansion-panel-content {
                margin: 0;
                padding: 0;
                .v-list {
                    padding: 0;
                    .v-list-item__content {
                        padding: 0 !important;
                    }
                }
            }
        }
    }
    .doc-page {
        padding-bottom: 10px;
        h1 {
            margin-bottom: 10px;
        }
        h2 {
            margin: 16px 0 6px 0;
            color: rgb(var(--v-theme-text));
            font-size: 1rem;
        }
        h3 {
            margin-bottom: 8px;
        }
        p {
            margin-bottom: 10px;
        }
        pre {
            padding: 16px;
        }
        table { 
            border-collapse: collapse !important;
            margin: 16px 0 16px 0;
            th {
                text-align: left;
                padding-left: 2px;
                border-bottom: 1px solid rgba(var(--v-theme-border)) !important;
            }
            tr:not(:last-child) {
                border-bottom: 1px solid rgba(var(--v-theme-border)) !important;
            }
            td {
                border: none !important;
                padding: 6px 2px 6px 2px;
            }
            th:not(:first-child), td:not(:first-child) {
                padding-left: 10px;
            }
            th:not(:last-child), td:not(:last-child) {
                border-right: 1px solid rgba(var(--v-theme-border)) !important;
                padding-right: 10px;
            }
        }
        .suggest {
            h4 {
                font-weight: normal;
                margin-bottom: 0;
            }
            .v-textarea {
                margin-top: 0 !important;
                textarea {
                    padding: 14px;
                    border: 1px solid rgb(var(--v-theme-background-lighten-1));
                }
            }
        }
    }
    .results {
        margin: 0 20px 0 20px;
        padding: 4px 10px 4px 10px;
        border: 1px solid rgb(var(--v-theme-background-lighten-1));
        .v-list-item {
            padding-left: 0px;
        }
        .v-list-item__content {
            padding: 6px 0;
            color: rgb(var(--v-theme-text));
        }
    }
    img {
        width: 100%;
        margin: 6px 0 10px 0;
    }
    .light img {
        border: 1px solid #dddddd;
        border-radius: 4px;
        box-shadow: 4px 4px 4px #e8e8e8;
    }
    a {
        text-decoration: none;
    }
    ul {
        margin-bottom: 16px;
    }
    .width-10 {
        width: 10% !important;
    }
    .width-15 {
        width: 15% !important;
    }
    .width-20 {
        width: 20% !important;
    }
    .width-25 {
        width: 25% !important;
    }
    .width-30 {
        width: 30% !important;
    }
    .width-35 {
        width: 35% !important;
    }
    .width-40 {
        width: 40% !important;
    }
    .width-45 {
        width: 45% !important;
    }
    .width-50 {
        width: 50% !important;
    }
    .width-55 {
        width: 55% !important;
    }
    .width-60 {
        width: 60% !important;
    }
    .width-65 {
        width: 65% !important;
    }
    .width-70 {
        width: 70% !important;
    }
    .width-75 {
        width: 75% !important;
    }
    .width-80 {
        width: 80% !important;
    }
    .width-85 {
        width: 85% !important;
    }
    .width-90 {
        width: 90% !important;
    }
    .width-95 {
        width: 95% !important;
    }
    .width-100 {
        width: 100% !important;
    }
}
</style>
