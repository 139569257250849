/*
    Route setup for apps using Display.json
 */
import {Auth} from '@/paks/vu-auth/Auth.js'
import {Feedback} from '@/paks/vu-feedback'
import {State} from '@/paks/vu-state'
import {titlecase} from '@/paks/js-polyfill'
import clone from '@/paks/js-clone'

export class RoutesClass {

    init(router, components = []) {
        this.router = router
        this.components = components || []
        for (let [key, component] of Object.entries(components)) {
            this.components[key] = component
        }
    }

    async replaceRoutes(display) {
        let routes = this.router.getRoutes()
        routes.forEach((route) => {
            if (route.name) {
                this.router.removeRoute(route.name)
            }
        })
        await this.addRoutes(display)
    }

    async addRoutes(display) {
        State.app.setContext({
            desktop: navigator.innerWidth >= 640,
            mobile: navigator.innerWidth < 640,
            agent: navigator.userAgent,
            height: window.innerWidth,
            language: navigator.language,
            width: window.innerWidth,
        })
        this.display = clone(display)
        for (let view of this.display.views) {
            await this.addView(view)
        }
        document.title = State.app.display.title
    }

    async addView(view, parent = null, role = null) {
        //  Inherit parent role if not defined
        view.role = view.role !== undefined ? view.role : role

        /*
            Check the properties in view.enable: {}
         */
        if (!State.app.testContext(view.enable, true, true)) {
            return
        }
        if (view.panels) {
            await this.definePanels(view)
        }
        /*
            Name defaults to path's first segment if unset
            Path defaults to view name if unset
            Title defaults to a TitleCase(name)
         */
        // view.path = this.rebase(parent, view.path != null ? view.path : view.name)
        // view.redirect = this.rebase(view, view.redirect)
        view.path = view.path != null ? view.path : view.name
        view.name = view.name || view.path.replace(/^\//, '').split('/').at(-1)
        let title = (view.title = view.title || titlecase(view.name))

        /*
            view.tabs == false              // No tabs
            view.tabs == [{to,name},...]    // Explicit tabs
            view.tabs == []                 // new tab set
         */
        if (view.tabs) {
            if (view.views) {
                let empty = view.tabs.length == 0
                for (let child of view.views) {
                    child.tab = child.tab || child.name || child.path.replace(/^\//, '').split('/').at(-1)
                    if (empty) {
                        view.tabs.push({
                            path: child.path, 
                            name: child.tab,
                            enable: child.enable,
                            role: child.role,
                        })
                    }
                    child.parentTabs = view.tabs
                }
            }
        }
        let metaView = clone(view)

        this.setComponents(view)
        if (view.views) {
            for (let child of view.views) {
                await this.addView(child, view, role)
            }
        }
        let route = {
            component: view.component,
            name: view.name,
            path: view.path,
            redirect: view.redirect,
            meta: {role, title, view: metaView},
            children: view.children || [],
            beforeEnter: this.enterRoute,
        }
        this.router.addRoute(route)
    }

    setComponents(view) {
        view.component = (typeof view.component == 'string') ?  this.getComponent(view.component) : view.component
        if (view.children) {
            for (let child of view.children) {
                this.setComponents(child)
            }
        }
        if (view.tabs && view.views) {
            for (let child of view.views) {
                child.tabs = view.tabs
                child.tab = child.tab || child.name
            }
        }
        return view    
    }

    async definePanels(view) {
        for (let panel of view.panels) {
            if (panel.path) {
                panel.path = `${view.path}/${panel.path}`
            }
            panel.fields = panel.fields || []
            if (panel.component) {
                panel.componentRef = this.getComponent(panel.component)
            }
        }
    }

    enterRoute(to, from, next) {
        //  WARNING: "this" is not equal to this class
        if (State.app.unsaved && State.app.unsaved(to)) {
            next(false)
            return
        }
        let {meta} = to
        let {role} = meta

        if (!Auth.can(role)) {
            Feedback.error('Insufficient Privilege')
            next(false)
            return
        }
        State.app.location.prior = to.path
        State.app.setActivity()

        if (meta?.view?.enable && !State.app.testContext(meta?.view?.enable, true, false)) {
            next('/')
            return
        }
        /*
            Extract params
         */
        for (let [key, value] of Object.entries(to.params)) {
            if (key == 'deviceId' && value == 'select') continue
            if (key == 'id') continue
            State.app.setContext(key, value)
        }
        next()
    }

    getComponent(name) {
        if (!name) {
            return null
        }
        let component = this.components[name]
        if (!component) {
            //  Check globally registered components
            let components = State.ref.vue._context.components
            if (components[name]) {
                return components[name]
            }
            console.error(`Cannont find component ${name}`)
            return null
        }
        return component
    }

    getComponents(vcomponents = false) {
        if (!vcomponents) {
            return this.components
        }
        return Object.assign({}, this.components, State.ref.vue._context.components)
    }

    getPanel(path = window.location.pathname, extra = '') {
        let view = this.getView(path)
        if (!view || !view.panels) {
            return null
        }
        if (extra) {
            path = `${path}/${extra}`
        }
        for (let panel of view.panels) {
            if (path.indexOf(panel.path) == 0) {
                return panel
            }
        }
        return null
    }

    /*
        Return the route.meta.view for the path
     */
    getView(path) {
        let routes = this.router.getRoutes()
        for (let route of routes) {
            if (route.path == path) {
                return route.meta.view
            }
        }
        return null
    }

    /*
        Return {views, key, view} in the State.app.display.views
     */
    getDisplayView(path, parent = State.app.display) {
        if (path == parent.path) {
            return parent
        }
        for (let [key, view] of Object.entries(parent.views)) {
            if (view.path == path) {
                return {views: parent.views, key, view}
            } 
            if (view.views) {
                let found = this.getDisplayView(path, view)
                if (found) {
                    return found
                }
            }
        }
        return null
    }

    template(s, ...contexts) {
        if (!contexts || !s) return s
        for (let context of contexts) {
            s = s.replace(/:([^/ ]+)/g, (a, b) => {
                if (context[b]) {
                    return context[b]
                } else {
                    return a
                }
            })
        }
        return s
    }
}

const Routes = new RoutesClass()

export {Routes}