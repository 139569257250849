<script setup>
import {onBeforeMount, reactive, ref, watch} from 'vue'
import {Feedback, State, allow, can, clone, deny, unique} from '@/paks/vu-app'
import {Action, Metric, Token} from '@/models'
import Expression from '@/paks/js-expression'
import Json5 from 'json5'

const Sources = [
    {title: 'Database Value (polled)', value: 'database'},
    {title: 'Database Update', value: 'sync'},
    {title: 'EventBridge Event', value: 'eventbridge'},
    {title: 'HTTP Request', value: 'http'},
    {title: 'Metric Value', value: 'metric'},
    {title: 'Scheduled', value: 'schedule'},
    {title: 'User Widget Trigger', value: 'user'},
]

const Types = [
    {name: 'Builder Alert', type: 'builder-alert'},
    {name: 'Builder Metric', type: 'builder-metric'},
    {name: 'Database', type: 'database'},
    {name: 'Device Commands', type: 'device'},
    {name: 'Email', type: 'email'},
    {name: 'HTTP', type: 'http'},
    {name: 'Manager Alert', type: 'alert'},
    {name: 'Manager Metric', type: 'metric'},
    {name: 'MQTT', type: 'mqtt'},
    {name: 'EventBridge', type: 'eventbridge', require: 'dedicated'},
    {name: 'Lambda', type: 'lambda', require: 'dedicated'},
    {name: 'SMS', type: 'sms', require: 'dedicated'},
]

const Spans = [
    {units: 'minutes', period: 60},
    {units: 'hours', period: 3600},
    {units: 'days', period: 86400},
    {units: 'weeks', period: 7 * 86400},
    {units: 'months', period: 28 * 86400},
    {units: 'years', period: 365 * 86400},
]

const Formats = [
    {title: 'Text', value: 'text', actions: ['email', 'sms']},
    {
        title: 'JSON',
        value: 'json',
        actions: [
            'alert',
            'builder-alert',
            'builder-metric',
            'database',
            'device',
            'eventbridge',
            'http',
            'lambda',
            'metric',
            'mqtt',
        ],
    },
    {title: 'HTML', value: 'html', actions: ['email']},
]

const Severities = ['info', 'warning', 'error', 'critical']

const Polled = {
    database: true,
    metric: true,
}

const Subject = {
    alert: true,
    'builder-alert': true,
    email: true,
    sms: true,
}

const DeviceCommands = [
    {title: 'Reboot', value: 'reboot'},
    {title: 'Release', value: 'release'},
    {title: 'Update', value: 'update'},
    {title: 'Custom', value: 'custom'},
]

const DatabaseCommands = [
    {title: 'Create', value: 'create'},
    {title: 'Update', value: 'update'},
    {title: 'Upsert', value: 'upsert'},
    {title: 'Remove', value: 'remove'},
]

//  MOB - this is confusing to be 'Message'. Better to be Properties and always be an object and
const Message = {
    alert: {label: 'Alert message', type: 'textarea', hint: 'Text'},
    'builder-alert': {label: 'Builder alert message', type: 'textarea', hint: 'Text'},
    'builder-metric': {label: 'Metric value', type: 'text', hint: 'Numeric Value'},
    database: {label: 'Database properties and keys', type: 'textarea', hint: 'JSON'},
    device: {label: 'Operation properties', type: 'textarea', hint: 'JSON'},
    email: {label: 'Email message', type: 'textarea', hint: 'Text'},
    http: {label: 'Request Body', type: 'textarea', hint: 'Text or JSON'},
    lambda: {label: 'Request Body', type: 'textarea', hint: 'JSON'},
    metric: {label: 'Metric value', type: 'text', hint: 'Numeric Value'},
    mqtt: {label: 'MQTT Message', type: 'textarea', hint: 'Text or JSON'},
    sms: {label: 'SMS Message', type: 'textarea', hint: 'Text'},
}

const Ranges = [
    {title: 'Last 5 Mins', value: 5 * 60 * 1000},
    {title: 'Last Hour', value: 3600 * 1000},
    {title: 'Last Day', value: 86400 * 1000},
    {title: 'Last Week', value: 7 * 86400 * 1000},
    {title: 'Last Month', value: 28 * 86400 * 1000},
    {title: 'Last Year', value: 365 * 86400 * 1000},
]

const TimeUnits = ['minutes', 'hours', 'days', 'weeks', 'months']

const props = defineProps({
    cloudId: String,
    modelValue: Object,
})
const emit = defineEmits(['input', 'update:modelValue'])

const page = reactive({
    action: {},
    canAdd: can('admin'),
    cloud: null,
    count: 1,
    deviceScope: null,
    dimensions: [],
    example: null,
    expiresUnits: null,
    fetching: null,
    hasMetrics: null,
    headers: null,
    metrics: [],
    namespaces: [],
    pollUnits: null,
    ready: null,
    rearmUnits: null,
    rules: [],
    saving: false,
    types: [],
    units: 'hours',
})

const Statistics = ['avg', 'current', 'min', 'max', 'sum']

//  Component refs
const confirm = ref(null)
const self = ref(null)

var metricPromise = null

onBeforeMount(() => {
    let action = (page.action = clone(props.modelValue))
    let {args, context} = action
    /*
        The context holds the trigger metric dimensions
        The args hold the action dimensions
     */
    args.dimensions = args.dimensions ? dimensionsToString(args.dimensions) : 'All'
    context.dimensions = context.dimensions ? dimensionsToString(context.dimensions) : 'All'
    if (context.properties) {
        context.properties = JSON.stringify(action.context.properties, null, 4) + '\n'
    }
    if (action.poll) {
        let {units, period} = getTimeUnits(action.poll)
        action.poll = period
        page.pollUnits = units
    } else {
        page.pollUnits = 'minutes'
    }

    if (action.rearm) {
        let {units, period} = getTimeUnits(action.rearm)
        action.rearm = period
        page.rearmUnits = units
    } else {
        page.rearmUnits = 'minutes'
    }
    if (action.args?.expires) {
        let {units, period} = getTimeUnits(action.args.expires)
        action.args.expires = period
        page.expiresUnits = units
    } else {
        page.expiresUnits = 'days'
    }
    //  DEPRECATE
    if (action.message && !action.args.message) {
        action.args.message = action.message
        action.message = null
    }
    action.severity = action.severity || 'info'
    action.buffer = action.buffer || {}
    action.cloudId = props.cloudId

    //  Actions
    if (action.type == 'http') {
        let headers = []
        for (let [key, value] of Object.entries(action.args.headers || {})) {
            headers.push(`${key}: ${value}`)
        }
        page.headers = headers.join('\n')
    } else if (action.type == 'device') {
        if (action.args.deviceId != null) {
            page.deviceScope = 'Device'
        } else if (action.args.accountId == 'all') {
            page.deviceScope = 'All'
        } else if (action.args.accountId != null) {
            page.deviceScope = 'Account'
        } else {
            page.deviceScope = 'Device'
        }
    }

    if (action.args.delay) {
        let {units, period} = getTimeUnits(action.args.delay)
        page.units = units
        page.delay = period
    }

    watch(() => page.action.trigger, changeAction, {immediate: true})
    watch(() => page.action.type, changeAction)

    //  MOB - replicate above
    watch(
        () => page.action.cloudId,
        (v, p) => change('cloudId', v, p),
        {immediate: true}
    )
    watch(
        () => page.action.context.namespace,
        (v, p) => change('namespace', v, p),
        {immediate: true}
    )
    watch(
        () => page.action.context.metric,
        (v, p) => change('metric', v, p),
        {immediate: true}
    )
})

function getTimeUnits(period) {
    let units = 'minutes'
    if (period >= 28 * 86400 * 1000) {
        units = 'months'
        period /= 28 * 86400 * 1000
    } else if (period >= 7 * 86400 * 1000) {
        units = 'weeks'
        period /= 7 * 86400 * 1000
    } else if (period >= 1 * 86400 * 1000) {
        units = 'days'
        period /= 1 * 86400 * 1000
    } else if (period >= 3600 * 1000) {
        units = 'hours'
        period /= 3600 * 1000
    } else if (period >= 60 * 1000) {
        units = 'minutes'
        period /= 60 * 1000
    }
    return {units, period: Math.round(period)}
}

function getTimePeriod(count, units) {
    let period = 0
    count = parseInt(count)
    if (units == 'months') {
        period = count * 28 * 86400 * 1000
    } else if (units == 'weeks') {
        period = count * 7 * 86400 * 1000
    } else if (units == 'days') {
        period = count * 1 * 86400 * 1000
    } else if (units == 'hours') {
        period = count * 3600 * 1000
    } else if (units == 'minutes') {
        period = count * 60 * 1000
    }
    return period
}

async function changeAction() {
    filterTypes()
    let action = page.action
    let {trigger, type} = action
    if (!page.schema && (type == 'database' || trigger == 'sync' || trigger == 'database')) {
        /*
            Update applicable schema and models 
         */
        page.schema = await Metric.getDeviceSchema({cloudId: action.cloudId})
        page.models = Object.keys(page.schema.models).filter(
            (m) => m != '_Schema' && m != '_Migration'
        )
    }
    if ((trigger == 'database' || trigger == 'metric') && action.poll == 0) {
        action.poll = 1
        page.pollUnits = 'minutes'
    } else if (trigger == 'schedule') {
        action.args.days = action.args.days || {}
    }
    if (!action.id) {
        if (type == 'alert' || type == 'builder-alert') {
            action.destination = null
        } else if (type == 'database') {
            action.format = 'json'
        } else if (type == 'device') {
            action.args.expires = 2
            page.expiresUnits = 'days'
        } else if (type == 'email') {
            action.destination = null
            action.format = 'html'
        } else if (type == 'eventbridge') {
            action.destination = 'default'
            action.format = 'json'
        } else if (type == 'http') {
            action.destination = null
            action.args.method = 'POST'
            action.format = 'json'
        } else if (type == 'lambda') {
            action.destination = null
            action.format = 'json'
        } else if (type == 'metric' || type == 'builder-metric') {
            action.destination = null
            action.format = 'json'
        } else if (type == 'mqtt') {
            action.destination = null
            action.format = 'json'
        } else {
            action.destination = null
        }
    }
    page.action.args.command = null

    let cloud = State.cache.clouds.find((c) => c.id == page.action.cloudId)
    if (cloud) {
        let tokens = await Token.find({
            type: 'CloudAPI',
            cloudId: cloud.id,
            owner: `cloud:${cloud.id}`,
            enable: true,
        })
        let cloudToken = tokens[0] || {}
        if (cloudToken) {
            page.example = `
curl -X POST \\
    ${cloud.api}/tok/action/invoke \\
    -H 'Authorization: ${cloudToken.id}' \\
    -H 'Content-Type: application/json' \\
    -d '{"name":"MyAction", "params":{}, "context":{}}'`
        }
    }
}

function filterTypes() {
    /*
        Get filtered list of actions
     */
    let {action} = page
    let types = Types
    if (action.trigger != 'user' && action.trigger != 'sync') {
        //  Can only use alert action if a manager accountId is available
        types = types.filter((c) => c.type != 'manager-alert' && c.type != 'manager-metric')
    }
    let cloud = State.get('Cloud', props.cloudId)
    if (cloud?.type != 'dedicated') {
        //  Can only use SMS for dedicated clouds
        types = types.filter((c) => c.require != 'dedicated')
    }
    page.types = types.sort((a, b) => a.name.localeCompare(b.name))

    if (action.type && !types.find((c) => c.type == action.type)) {
        action.type = null
    }
    if (types.length == 1 || !action.cloudId) {
        action.type = types[0]?.type
    }
}

async function change(tag, value, prior) {
    let action = page.action
    let context = action.context
    //  Wait for other changes to complete
    while (page.fetching) {
        await metricPromise
    }
    page.fetching = true

    //  Clear downstream properties
    if (prior) {
        if (tag == 'cloudId') {
            context.namespace = null
        }
        if (tag == 'namespace' || tag == 'cloudId') {
            context.metric = null
        }
        if (tag == 'metric' || tag == 'namespace' || tag == 'cloudId') {
            context.dimensions = null
        }
    }
    metricPromise = new Promise(async (resolve, reject) => {
        try {
            //  Fetch metrics and pick out results
            if (action.cloudId) {
                let results = await getMetricList(tag, action)
                if (results.namespaces) {
                    if (tag == 'cloudId') {
                        page.namespaces = sortNamespaces(results.namespaces)
                    }
                }
                if (tag == 'cloudId' || tag == 'namespace') {
                    page.metrics = results.metrics || []
                }
                if (tag == 'cloudId' || tag == 'namespace' || tag == 'metric') {
                    page.dimensions = makeDimensionsList(results.dimensions)
                }
            }
            resolve(true)
        } catch (err) {
            reject(err)
        }
    })
    //  Wait for completion
    await metricPromise
    page.fetching = false
}

async function getMetricList(tag, action) {
    let params = {}
    for (let key of ['cloudId', 'namespace', 'metric']) {
        if (key == 'cloudId') {
            params.cloudId = action.cloudId
        } else if (action.context[key] != null) {
            params[key] = action.context[key]
        }
        if (key == tag) break
    }
    return await Metric.getMetricList(params)
}

function sortNamespaces(list) {
    return unique(
        list
            .map((i) => i.replace('@', '|'))
            .sort()
            .map((i) => i.replace('|', '@'))
    )
}

async function preSave(validate) {
    let {action} = page
    action.error = null
    if (action.expression) {
        let expression = new Expression()
        try {
            expression.parse(action.expression)
        } catch (err) {
            await validate.fieldError(self.value, 'expression', 'Expression fails to parse')
            return false
        }
    }
    if (Message[action.type].hint == 'JSON') {
        try {
            //  DEPRECATE action.message
            let data = Json5.parse(action.args.message || action.message || '{}')
            action.message = JSON.stringify(data, null, 4)
        } catch (err) {
            await validate.fieldError(self.value, 'message', 'Must supply valid JSON')
            return false
        }
    }
    action.poll = Math.max(action.poll, 5)
    return true
}

async function save() {
    let action = clone(page.action)
    action = deny(action, ['due', 'select'])
    let {args, context} = action

    if (args.dimensions) {
        args.dimensions = dimensionsToObject(args.dimensions)
    }
    if (context.dimensions) {
        context.dimensions = dimensionsToObject(context.dimensions)
    }
    if (context.properties) {
        context.properties = Json5.parse(context.properties)
    }
    if (action.poll) {
        action.poll = getTimePeriod(action.poll, page.pollUnits)
    }
    if (action.rearm) {
        action.rearm = getTimePeriod(action.rearm, page.rearmUnits)
    }
    if (action.args?.expires) {
        action.args.expires = getTimePeriod(action.args?.expires, page.expiresUnits)
    }
    if (!Polled[action.trigger]) {
        action.poll = 0
    }
    /*
        TODO - validate args
        account (event bus)
        accountId
        command
        days
        delay
        deviceId
        dimensions
        expires
        headers
        method
        message
        model
        subject
        time
     */
    if (action.type == 'sms') {
        action.format = 'text'
    } else if (action.type == 'http') {
        action.args.headers = {}
        if (page.headers) {
            for (let item of page.headers.split(/\r?\n/)) {
                let [key, value] = item.split(':')
                action.args.headers[key.trim()] = value.trim()
            }
        }
    } else if (action.type == 'device') {
        //  TODO - same for all other types
        action.args = allow(action.args, ['accountId', 'deviceId', 'expires', 'message', 'command'])
    } else if (action.type == 'mqtt') {
        action.format = 'json'
    } else if (action.type == 'delay') {
        let span = Spans.find((s) => page.units == s.units)
        action.args.delay = page.count * span.period
    }
    if (page.deviceScope == 'All') {
        action.args.accountId = 'all'
    } else if (page.deviceScope == 'Account') {
        action.args.accountId = action.args.accountId || ''
        delete action.args.deviceId
    } else {
        action.args.deviceId = action.args.deviceId || ''
        delete action.args.accountId
    }

    if (action.id) {
        await Action.update(action)
    } else {
        await Action.create(action)
    }
    emit('update:modelValue', action)
    emit('input')
}

async function deleteAction() {
    let action = page.action
    if (!(await confirm.value.ask(`Do you want to delete the action "${action.name}"? `))) {
        return
    }
    await Action.remove({id: action.id, cloudId: action.cloudId})
    Feedback.info('Action Deleted')
    emit('input')
}

//  MOB - need j-dimensions library
/*
    Convert the CustomMetrics dimensions list to a key=value,... list suitable for a select input
 */
function makeDimensionsList(list) {
    let dimensions = []
    if (!list || list.length == 0) {
        list = [{}]
    }
    for (let dimension of list) {
        let set = []
        for (let [key, value] of Object.entries(dimension)) {
            if (key == 'Hub' || key == 'Cloud') {
                let cloud = State.get('Cloud', value)
                if (cloud) {
                    value = cloud.name
                } else {
                    continue
                }
            } else if (key == 'Product') {
                let product = State.get('Product', value)
                if (product) {
                    value = product.name
                } else {
                    continue
                }
            }
            set.push(`${key}=${value}`)
        }
        if (set.length) {
            dimensions.push(set.sort().join(', '))
        } else {
            dimensions.push('All')
        }
    }
    return unique(dimensions.sort())
}

function dimensionsToString(dimensions) {
    let set = []
    if (dimensions) {
        for (let [key, value] of Object.entries(dimensions)) {
            if (key == 'Hub' || key == 'Cloud') {
                let cloud = State.get('Cloud', value)
                if (cloud) {
                    value = cloud.name
                } else {
                    continue
                }
            } else if (key == 'Product') {
                let product = State.get('Product', value)
                if (product) {
                    value = product.name
                } else {
                    continue
                }
            } else if (!key) {
                continue
            }
            set.push(`${key}=${value}`)
        }
    }
    return set.sort().join(', ')
}

/*
    Dimensions are an object of multiple properties. If {}, that means "All".
 */
function dimensionsToObject(dimensions) {
    let result = {}
    if (!dimensions) {
        dimensions = ''
    }
    for (let dimension of dimensions.split(',').map((d) => d.trim())) {
        let [key, value] = dimension.split('=').map((p) => p.trim())
        if (key == 'All' || key == '*' || !key) {
            return {}
        } else {
            if (key == 'Hub' || key == 'Cloud') {
                value = props.cloud.id
            } else if (key == 'Product') {
                let product = State.get('Product', value)
                if (product) {
                    value = product.name
                }
            }
            result[key] = value
        }
    }
    return result
}

function getFormats() {
    return Formats.filter((f) => f.actions.indexOf(page.action.type) >= 0)
}
</script>

<template>
    <vu-form
        class="action-edit"
        help="/doc/ui/automations/actions/edit.html"
        ref="self"
        :data="page"
        :title="`${page.action.id ? 'Modify' : 'Add'} Action`"
        :options="{enter: false}"
        :pre-save="preSave"
        :save="save">
        <vu-sign name="action-edit-sign-1" title="Automation Action" color="accent">
            <p>
                Configure your automation action by defining the trigger condition and matching
                expression. Then define the action parameters.
            </p>
        </vu-sign>

        <v-alert v-if="page.action.error" class="vrow mb-5" type="error">
            {{ page.action.error }}
        </v-alert>

        <vu-input
            label="Name"
            name="name"
            type="text"
            v-model="page.action.name"
            variant="outlined"
            :rules="page.rules.simpleName" />

        <vu-input
            label="Description"
            name="description"
            type="text"
            v-model="page.action.description"
            variant="outlined" />

        <!--
        <vu-input
            v-model="page.action.cloudId"
            label="Device Cloud"
            name="cloud"
            type="select"
            density="compact"
            variant="outlined"
            cols="6"
            :items="page.clouds" />
        -->

        <label class="divider">Trigger</label>

        <vu-input
            v-model="page.action.trigger"
            label="Source"
            placeholder="Leave blank for always true"
            name="type"
            type="select"
            density="compact"
            variant="outlined"
            cols="6"
            :items="Sources" />

        <div v-if="page.action.trigger == 'http'" class="example">
            <label>
                Example REST invocation
                <vu-clip class="float-right mr-1" :text="page.example" />
            </label>
            <pre>
                {{ page.example }}
            </pre>
        </div>

        <div v-if="page.action.trigger == 'metric'" class="vrow">
            <vu-input
                v-model="page.action.context.namespace"
                name="metric-namespace"
                label="Metric Namespace"
                type="select"
                :items="page.namespaces"
                cols="6"
                variant="outlined" />
            <vu-input
                v-model="page.action.context.metric"
                name="metric-name"
                label="Metric Name"
                type="select"
                :items="page.metrics"
                cols="6"
                variant="outlined" />
            <vu-input
                v-model="page.action.context.dimensions"
                name="metric-dimensions"
                label="Metric Dimensions"
                type="select"
                :items="page.dimensions"
                cols="12"
                variant="outlined" />
            <vu-input
                v-model="page.action.context.statistic"
                name="metric-statistic"
                label="Metric Statistic"
                type="select"
                cols="6"
                flat
                :items="Statistics"
                :rules="page.rules.required"
                variant="outlined" />
            <vu-input
                v-model="page.action.context.period"
                name="metric-period"
                label="Metric Period"
                type="select"
                :items="Ranges"
                cols="6"
                variant="outlined" />
        </div>
        <div v-if="page.action.trigger == 'database' || page.action.trigger == 'sync'" class="vrow">
            <vu-input
                type="select"
                name="database-model"
                label="Database Table"
                variant="outlined"
                density="compact"
                cols="12"
                :items="page.models"
                v-model="page.action.context.model" />

            <vu-input
                type="textarea"
                name="database-properties"
                label="Matching Item Properties"
                cols="12"
                placeholder="{ Matching properties and keys in JSON format }"
                variant="outlined"
                density="compact"
                v-model="page.action.context.properties" />
        </div>

        <div v-if="page.action.trigger == 'schedule'" class="vrow">
            <label class="vrow days">Scheduled Days</label>
            <vu-input
                v-for="day in ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
                type="checkbox"
                name="schedule-days"
                :label="day"
                variant="outlined"
                density="compact"
                cols="1"
                v-model="page.action.args.days[day]" />

            <vu-input
                type="text"
                name="schedule-time"
                label="Schedule Time"
                placeholder="HH:MM in 24-hr clock"
                cols="12"
                variant="outlined"
                density="compact"
                v-model="page.action.args.time" />
        </div>

        <vu-input
            v-if="page.action.trigger != 'http'"
            label="Matching Expression"
            name="expression"
            type="text"
            :clearable="true"
            v-model="page.action.expression"
            placeholder="Blank for always true"
            variant="outlined" />

        <vu-input
            v-if="Polled[page.action.trigger]"
            label="Poll Frequency"
            name="poll"
            placeholder="5 mins or more"
            type="text"
            v-model="page.action.poll"
            cols="3"
            variant="outlined" />

        <vu-input
            v-if="Polled[page.action.trigger]"
            v-model="page.pollUnits"
            name="poll-units"
            label="Units"
            type="select"
            cols="3"
            variant="outlined"
            density="compact"
            :items="TimeUnits" />

        <label class="divider">Action Parameters</label>

        <vu-input
            label="Action"
            name="action"
            type="select"
            v-model="page.action.type"
            variant="outlined"
            cols="12"
            density="compact"
            :items="page.types"
            item-title="name"
            item-value="type" />

        <div v-if="page.action.type == 'database'" class="vrow">
            <vu-input
                type="select"
                name="database-command"
                label="Database Command"
                variant="outlined"
                density="compact"
                :items="DatabaseCommands"
                cols="6"
                v-model="page.action.args.command" />

            <vu-input
                type="select"
                name="action-model"
                label="Table (model)"
                variant="outlined"
                density="compact"
                :items="page.models"
                cols="6"
                v-model="page.action.args.model" />
        </div>

        <div v-if="page.action.type == 'device'" class="vrow">
            <vu-input
                type="combo"
                label="Device Command"
                name="device-command"
                cols="12"
                variant="outlined"
                density="compact"
                :items="DeviceCommands"
                v-model="page.action.args.command" />
        </div>

        <div v-if="page.action.type == 'email'" class="vrow">
            <vu-input
                label="Email Addresses"
                cols="12"
                placeholder="email, email, ..."
                variant="outlined"
                v-model="page.action.destination" />

            <v-radio-group v-model="page.action.format" label="Message Format" inline>
                <v-radio
                    v-for="format of getFormats()"
                    color="primary"
                    :label="format.title"
                    :value="format.value"></v-radio>
            </v-radio-group>
        </div>

        <div v-if="page.action.type == 'eventbridge'" class="vrow">
            <vu-input
                label="Event Bus Name"
                cols="12"
                placeholder="default"
                type="text"
                v-model="page.action.destination"
                variant="outlined"
                :rules="page.rules.simpleName" />
            <vu-input
                label="Event Bus Account"
                cols="12"
                placeholder="Own account if blank"
                type="text"
                v-model="page.action.args.account"
                variant="outlined"
                :rules="page.rules.simpleName" />
        </div>

        <div v-if="page.action.type == 'http'" class="vrow">
            <vu-input
                v-model="page.action.destination"
                label="URL"
                type="text"
                variant="outlined"
                :rules="page.rules.url" />
            <vu-input
                v-model="page.action.args.method"
                label="HTTP Method"
                type="text"
                variant="outlined"
                :rules="page.rules.simpleName" />

            <vu-input
                label="HTTP Headers"
                cols="12"
                placeholder="Header: Value..."
                type="textarea"
                variant="outlined"
                v-model="page.headers" />
        </div>
        <div v-if="page.action.type == 'lambda'" class="vrow">
            <vu-input
                label="Function Name"
                cols="12"
                type="text"
                v-model="page.action.destination"
                variant="outlined"
                :rules="page.rules.simpleName" />
        </div>
        <div v-if="page.action.type == 'mqtt'" class="vrow">
            <vu-input
                v-model="page.action.destination"
                label="MQTT Topic"
                type="text"
                variant="outlined" />
        </div>
        <div v-if="page.action.type == 'sms'" class="vrow">
            <vu-input
                label="Phone Number"
                cols="12"
                type="text"
                v-model="page.action.destination"
                variant="outlined"
                :rules="page.rules.phone" />
        </div>

        <div v-if="Subject[page.action.type]" class="vrow">
            <vu-input
                label="Message Subject"
                name="subject"
                type="text"
                v-model="page.action.args.subject"
                variant="outlined"
                cols="6" />
            <vu-input
                label="Severity"
                name="severity"
                type="select"
                v-model="page.action.severity"
                variant="outlined"
                density="compact"
                cols="6"
                :items="Severities" />
        </div>

        <div
            v-if="page.action.type == 'metric' || page.action.type == 'builder-metric'"
            class="vrow">
            <vu-input
                v-model="page.action.args.metric"
                name="action-metric"
                label="Metric Name"
                type="text"
                cols="6"
                variant="outlined" />
            <vu-input
                v-model="page.action.args.dimensions"
                label="Metric Dimensions"
                name="action-dimensions"
                type="text"
                placeholder="Key=Value, ..."
                cols="6"
                variant="outlined" />
        </div>

        <vu-input
            v-if="page.action.type != 'delay'"
            :type="Message[page.action.type]?.type || 'textarea'"
            :label="Message[page.action.type]?.label || 'Message or value'"
            :name="`message-${page.action.type}`"
            :placeholder="Message[page.action.type]?.hint || 'Text'"
            cols="12"
            variant="outlined"
            v-model="page.action.args.message" />

        <div
            v-if="page.action.type == 'metric' || page.action.type == 'builder-metric'"
            class="vrow">
            <vu-input
                type="text"
                label="Buffer Count"
                name="buffer-count"
                placeholder="Updates before emitting"
                cols="6"
                variant="outlined"
                v-model="page.action.buffer.count" />
            <vu-input
                type="text"
                label="Buffer Delay"
                name="buffer-delay"
                placeholder="Delay in secs before emitting"
                cols="6"
                variant="outlined"
                v-model="page.action.buffer.delay" />
        </div>

        <vu-input
            v-if="page.action.type == 'device'"
            v-model="page.deviceScope"
            name="device-scope"
            label="Device Scope"
            type="radio"
            cols="6"
            variant="outlined"
            density="compact"
            :items="['All', 'Account', 'Device']" />

        <vu-input
            v-if="page.action.type == 'device' && page.deviceScope == 'Device'"
            class="mt-5"
            v-model="page.action.args.deviceId"
            name="device-id"
            label="Device ID"
            placeholder="Leave blank for invoking device"
            type="text"
            cols="6"
            variant="outlined"
            density="compact" />
        <vu-input
            v-else-if="page.action.type == 'device' && page.deviceScope == 'Account'"
            class="mt-5"
            v-model="page.action.args.accountId"
            name="device-id"
            label="Account ID"
            placeholder="Leave blank for invoking account"
            type="text"
            cols="6"
            variant="outlined"
            density="compact" />
        <div v-else-if="page.action.type == 'device'" class="vcol vcol-6 mt-5" />

        <vu-input
            v-if="page.action.type == 'device'"
            v-model="page.action.args.expires"
            name="expires-count"
            label="Command Lifespan"
            type="text"
            cols="6"
            variant="outlined"
            density="compact"
            :items="TimeUnits" />

        <vu-input
            v-if="page.action.type == 'device'"
            v-model="page.expiresUnits"
            name="expires-units"
            label="Units"
            type="select"
            cols="6"
            variant="outlined"
            density="compact"
            :items="TimeUnits" />

        <vu-input
            v-if="page.action.trigger"
            label="Re-Arm Delay"
            name="rearm"
            placeholder="Time period to suppress triggering again"
            type="text"
            v-model="page.action.rearm"
            cols="6"
            variant="outlined" />

        <vu-input
            v-if="page.action.trigger"
            v-model="page.rearmUnits"
            name="rearm-units"
            label="Units"
            type="select"
            cols="6"
            variant="outlined"
            density="compact"
            :items="TimeUnits" />

        <vu-input label="Enable" cols="12" type="checkbox" v-model="page.action.enable" />

        <div class="actions mt-0">
            <v-btn v-if="page.action.name" color="accent" type="submit" :loading="page.saving">
                Save
            </v-btn>
            <v-btn color="none" @click="emit('input')">Cancel</v-btn>
            <v-btn color="error" v-if="page.action.id" @click="deleteAction">Delete</v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss" scoped>
.action-edit {
    .divider {
        font-size: 1.2rem !important;
        margin-bottom: 16px !important;
        clear: both;
        width: 100%;
    }
    .days {
        font-size: 1.2rem !important;
        margin-bottom: 4px;
    }
    .v-radio-group > .v-input__control > .v-label {
        margin-inline-start: 0px !important;
    }
    .v-radio-group > .v-input__control > .v-label {
        margin-inline-start: 0px !important;
    }
    textarea {
        background: rgb(var(--v-theme-background-lighten-1));
        padding: 10px 0 10px 0;
    }
    .example {
        background: rgb(var(--v-theme-background-lighten-1));
        border-radius: 4px;
        margin: 0 16px 0 0;
        width: 100%;
        label {
            font-size: 1.1rem !important;
        }
        pre {
            font-size: 0.8rem;
        }
    }
}
</style>
<style lang="scss">
.action-edit {
    .v-radio-group {
        .v-label {
            margin-inline-start: 0 !important;
        }
        .v-selection-control-group {
            padding-inline-start: 0 !important;
        }
    }
}
</style>
